import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getOperadorContexto() {
      return new Promise((resolve, reject) => {
        axios.get('/agente/operador-contexto')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getScreeningOperacaoRank(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/${params.contexto}/aprovador/operacao-lista-rank`, {
          headers: {
            token: params.token,
          },
        })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    aprovar(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('/screening/operacao-aprovar', data, {
          headers: {
            'Content-Type': 'application/json',
            token: '12c7c0d4-15ec-1721-2227ac130002-22d7',
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
