<template>
  <div>
    <b-row
      v-if="data && data.length>0"
    >
      <b-col
        v-for="item in data"
        :key="item.id"
        cols="12"
        md="6"
      >
        <b-overlay
          :show="item.loadingOverlay"
          rounded="sm"
        >
          <b-card
            class="card-statistics"
            :title="item.estrategia"
          >
            <b-row class="mt-2 mb-2">
              <b-col
                cols="6"
                md="2"
                class="mb-2 mb-xl-0"
              >
                <b-media no-body>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0 center">
                        {{ item.tempoGrafico }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        Tempo Grafico
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                cols="6"
                md="2"
                class="mb-2 mb-xl-0"
              >
                <b-media no-body>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ item.capitalAlocado }}%
                      </h3>
                      <p class="mb-0 text-secondary">
                        Capital por operação
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                cols="6"
                md="2"
                class="mb-2 mb-xl-0"
              >
                <b-media no-body>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ item.instanciaLivreQtd }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        Slots Livres
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                cols="6"
                md="2"
                class="mb-2 mb-xl-0"
              >
                <b-media no-body>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ item.instanciaAlocadaQtd - item.instanciaLivreQtd }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        Slots Ocupados
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                cols="6"
                md="2"
                class="mb-2 mb-xl-0"
              >
                <b-media no-body>
                  <b-media-body
                    :class="`my-auto ${ windowWidth >= 576 ? 'd-flex align-items-start ml-1' : '' }`"
                  >
                    <div>
                      <h3 class="font-weight-bolder mb-0">
                        {{ item.instanciaAlocadaQtd }}
                      </h3>
                      <p class="mb-0 text-secondary">
                        Total de Slots
                      </p>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
            <b-row
              v-if="item.instanciaLivreQtd > 0"
              class="mb-1"
            >
              <b-col cols="12">
                <h5>
                  <b-badge
                    v-if="item.dataExpiracaoCountDown"
                    variant="warning"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      class="mr-25"
                    />
                    <span>Expira em {{ item.dataExpiracaoCountDown }}</span>
                  </b-badge>
                </h5>
              </b-col>
            </b-row>

            <template v-if="item.instanciaLivreQtd == 0">
              <h4>
                <div class="text-center my-2">
                  Nenhum Slot livre
                </div>
              </h4>
            </template>
            <template v-else>
              <b-table
                v-if="item"
                ref="refListTable"
                :items="item.screeningOperacaoList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="Nenhuma operação para aprovar"
                striped
              >
                <template #cell(ativo)="data">
                  {{ getScreeningOperacaDadosJson(data.item).ativo }}
                </template>

                <template #cell(direcao)="data">
                  {{ getScreeningOperacaDadosJson(data.item).direcao }}
                </template>

                <template #cell(acao)="data">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    size="sm"
                    @click.stop="confirmarAprovar(item, data.item.id)"
                  >
                    Aprovar
                  </b-button>
                </template>

              </b-table>
            </template>
          </b-card>

        </b-overlay>
      </b-col>
    </b-row>
    <b-row v-else>
      <h4>
        <div class="text-center my-2">
          Nenhuma instância requer aprovação
        </div>
      </h4>
    </b-row>
  </div>
</template>

<script>

import {
  BCard, BTable, BMedia, BMediaBody, BRow, BCol, BButton, BBadge, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import aprovarOperacaoStoreModule from './aprovarOperacaoStoreModule'

const MONITOR_APROVAR_OPERACAO_SPOT_STORE_MODULE_NAME = 'monitor-aprovar-operacao-spot'

export default {

  components: {
    BCard,
    BTable,
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BButton,
    BBadge,
    BOverlay,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      polling: null,
      tableColumns: [
        { key: 'ativo', label: 'Ativo' },
        { key: 'direcao', label: 'Direção' },
        { key: 'rank', label: 'Rank' },
        { key: 'acao', label: 'Ações' },
      ],
      data: [],
      timers: [],
      operadorData: null,
    }
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
  },

  created() {
    if (!store.hasModule(MONITOR_APROVAR_OPERACAO_SPOT_STORE_MODULE_NAME)) store.registerModule(MONITOR_APROVAR_OPERACAO_SPOT_STORE_MODULE_NAME, aprovarOperacaoStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    if (store.hasModule(MONITOR_APROVAR_OPERACAO_SPOT_STORE_MODULE_NAME)) store.unregisterModule(MONITOR_APROVAR_OPERACAO_SPOT_STORE_MODULE_NAME)
  },

  beforeUnmount() {
    this.timers.forEach(timerId => {
      clearInterval(timerId)
    })
  },

  methods: {

    async pollfetchList() {
      this.operadorData = await this.getOperadorContexto() // Await the Promise
      this.getScreeningOperacaoRank(this.operadorData)
      const self = this
      this.polling = setInterval(() => {
        self.getScreeningOperacaoRank(self.operadorData)
      }, 30000)
    },

    async getOperadorContexto() {
      const response = await this.$store.dispatch('monitor-aprovar-operacao-spot/getOperadorContexto')
      return response.data
    },

    getScreeningOperacaDadosJson(screeningOperacao) {
      if (screeningOperacao) {
        return JSON.parse(screeningOperacao.dadosJson)
      }
      return ''
    },

    getScreeningOperacaoRank(operadorData) {
      this.timers.forEach(timerId => {
        clearInterval(timerId)
      })
      this.$store.dispatch('monitor-aprovar-operacao-spot/getScreeningOperacaoRank', operadorData)
        .then(response => {
          this.data = response.data

          this.data.forEach(r => {
            if (r.loadingOverlay !== true) {
              // eslint-disable-next-line no-param-reassign
              this.$set(r, 'loadingOverlay', false)
            }
            if (r.screeningOperacaoList && r.screeningOperacaoList.length > 0) {
              const timerId = setInterval(() => {
                // eslint-disable-next-line no-param-reassign
                this.$set(r, 'dataExpiracaoCountDown', this.getCountdown(r))
              }, 1000)
              this.timers.push(timerId)
            }
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    getCountdown(operacao) {
      if (operacao.screeningOperacaoList) {
        const expirationTimestamp = operacao.screeningOperacaoList[0].dataExpiracao

        const now = new Date().getTime()
        const difference = expirationTimestamp - now

        if (difference <= 0) {
          // eslint-disable-next-line no-param-reassign
          const opttemp = this.data.find(o => o.id === operacao.id)
          opttemp.screeningOperacaoList = null
          return 'expirado'
        }

        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        return `${hours}h ${minutes}m ${seconds}s`
      }
      return ''
    },

    confirmarAprovar(item, operacaoId) {
      this.$swal({
        title: 'Tem certeza que deseja aprovar a operação?',
        text: 'A operação será aprovada em aproximadamente 30s',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode aprovar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.aprovar(item, operacaoId)
        }
      })
    },

    aprovar(item, screeningOperacaoId) {
      // eslint-disable-next-line no-param-reassign
      this.$set(item, 'loadingOverlay', true)
      this.$store.dispatch('monitor-aprovar-operacao-spot/aprovar', { operadorEstrategiaId: item.id, screeningOperacaoId })
        .then(() => {
          setTimeout(() => {
            this.getScreeningOperacaoRank(this.operadorData)
            this.$swal({
              icon: 'success',
              title: 'Operação aprovada!',
              text: 'A operação foi aprovada',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            // eslint-disable-next-line no-param-reassign
            this.$set(item, 'loadingOverlay', false)
          }, 30000)
        })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
